import * as React from 'react'
import { withTheme } from 'emotion-theming'
import { css, Global } from '@emotion/core'

const GlobalCss = withTheme(() => (
  <Global
    styles={css`
      h4,
      h5,
      h6 {
        font-family: 'PFDINTextProMedium', Helvetica;
      }

      li {
        svg {
          fill: #48d597;
        }
      }

      .top-bar {
        .top-nav {
          a {
            border-left: 1px solid #1d2329;
            &:first-of-type {
              border-left: none;
            }
          }
        }
      }

      .show-desktop,
      .show-mobile {
        display: none;
      }

      @media (min-width: 768px) {
        .show-desktop {
          display: block;
        }
      }

      @media (max-width: 768px) {
        .show-mobile {
          display: block;
        }
      }
    `}
  />
))

export default GlobalCss
